import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { getAuth } from "services/identity.service";
import SwiperCore from "swiper";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { getPlans, getPlansWithoutLogin } from "services/plans.service";
import { getInTouch } from "services/parents.service";
import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { AiOutlineCloseCircle, AiOutlineMenu } from "react-icons/ai";
import { yupValidator } from "lib/yup-validator";
import { Input } from "@material-ui/core";
import { InputType } from "atoms";
import Link from "next/link";
function LandingPageV3() {
  const router = useRouter();

  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.monkeybox.monkeyboxapp";
  const appStoreLink =
    "https://apps.apple.com/in/app/monkeybox-india/id1626287253";

  const [activePlanType, setActivePlanType] = useState("all");
  const [allPlans, setAllPlans] = useState([]);
  const [activeTypePlans, setActiveTypePlans] = useState([]);
  const [isGetinTouchError, setIsGetinTouchError] = useState(false);
  const [constactUsMessage, setConstactUsMessage] = useState(null);
  const [isContactUSLoading, setIsContactUSLoading] = useState(false);
  const planType = [
    "All",
    "Weekly",
    "Lunch Only",
    "Breakfast + lunch",
    "Breakfast + lunch + Snack",
  ];

  const plansTab = [
    {
      id: 1,
      label: "All",
      search: "",
    },
    {
      id: 2,
      label: "Weekly",
      search: "5 Days",
    },
    {
      id: 3,
      label: "Lunch Only",
      search: "Lunch Only",
    },
    {
      id: 4,
      label: "Breakfast + lunch",
      search: "Breakfast + lunch",
    },
    {
      id: 5,
      label: "Breakfast + lunch + Snack",
      search: "Breakfast + lunch + Snack",
    },
  ];

  const faqs = [
    {
      question: "How to place a meal plan order in premium breakfast + lunch",
      videoId: "CjmWh7i3ECc",
    },
    {
      question: "How to add on Snacks in Premium Breakfast + Lunch Plan?",
      videoId: "hs3C071A_Yk",
    },
    {
      question: "How to order the plan meal Lunch only 5 days?",
      videoId: "EO90ptTDSfE",
    },
    {
      question: "How to view plans in MonkeyBox app?",
      videoId: "K2UEexXXCeM",
    },
    {
      question: "How to cancel meal plan for the day?",
      videoId: "0w320R7r2pM",
    },
    {
      question: "How to edit child details in the MonkeyBox app?",
      videoId: "8ZG4dIWYML0",
    },
    {
      question: "How to cancel the subscription in Monkeybox app?",
      videoId: "6U_dzhNnbI8",
    },
    {
      question: "How to cancel the order in Monkeybox app?",
      videoId: "t3AejRgU_kg",
    },
    {
      question: "How to add lunch breakfast snacks?",
      videoId: "L2nUeHmwpcM",
    },
    {
      question: "What are the rates & delivery schedules for MonkeyBox meals?",
      answer:
        "Here are the rates & delivery plans for MonkeyBox meals: Breakfast: ₹75 onwards | Served 30 minutes before the morning break (between 8:30AM - 10:00AM, depending on the school) Lunch: ₹125 onwards (with a snack option for ₹90)| Served 30 minutes before the lunch break (between 11:30AM - 12:30PM, depending on the school)",
    },
    {
      question: "How will my child get the MonkeyBox tiffin?",
      answer:
        "We drop the meals at the school reception before breaks, with a tag for the child to collect it easily. Certain schools even allow us to drop meals outside classrooms!",
    },
    {
      question: "By when can the order be placed on MonkeyBox?",
      answer:
        "Please place an order, the evening before, by 8 pm! (We have initially left it opened till midnight, but this will end soon!)",
    },
    {
      question: "How can I find the food menu of MonkeyBox for my kids?",
      answer:
        "We share a weekly menu every Saturday evening with a list of all the nutritious & tasty vegetarian food options!",
    },
    {
      question: "How can I register with MonkeyBox to avail its services?",
      link: "link",
    },
    {
      question: "Is there a customer service number for MonkeyBox?",
      answer: "If you have any queries, please contact us on +919513370337",
    },
    {
      question: "How are the meals packaged for delivery?",
      answer:
        "The meals are packaged in plastic tiffin boxes. Your child’s name, class, section and combo is put in a sleeve on the jute bag and delivered half an hour before the break to the school. The school then gives the lunch box to your child. After that, the lunch box is collected by Monkeybox at the end of each day.",
    },
    {
      question: "What plans are available?",
      answer:
        "PREMIUM WEEKLY PLAN – ₹569. 5 day lunch only (1 day cancellation available). Save over 10% opting for the premium lunch plan. 5 DAY BREAKFAST + LUNCH PLAN – ₹899. Options available (1 day cancellation available)",
    },
    {
      question: "What varieties are present?",
      answer:
        "We give three options daily for Breakfast and Lunch and a snack add on option daily (Cuisines include North Indian, South Indian, Pan Asian and Continental cuisine)",
    },
    {
      question: "How many schools does MonkeyBox deliver to?",
      answer:
        "We deliver to 80+ schools and top educational institutes across the South East & Eastern parts of Bangalore.",
    },
  ];

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupValidator(
      yup.object().shape({
        name: yup.string().required("Name is required!").nullable(),
        mobile: yup
          .string()
          .min(5, "Mobile number is invalid!")
          .max(10, "Mobile number is invalid!")
          .required("Mobile is required!")
          .nullable(),
        email: yup.string().required("Email type is required!").nullable(),
        city: yup.string().required("City is required!").nullable(),
        message: yup.string().required("Message is required!").nullable(),
      })
    ),
    defaultValues: {
      name: null,
      mobile: null,
      email: null,
      city: null,
      message: null,
    },
  });

  const videoIds = ["8BmrSZNKN0M", "UBjON2GIN60"];
  const authCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("AUTH="));

  function scrollToDiv(sectionId) {
    var targetDiv = document.getElementById(sectionId);
    var offsetTop = targetDiv.offsetTop;

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    console.log(errors, "form error in get in touch form ");
  }, [errors]);

  const contactUsRequest = async (data, { resetForm }) => {
    setIsContactUSLoading(true);
    console.log(data, errors, "get in touch data ");
    try {
      const contactUsRes = await getInTouch(data);
      setConstactUsMessage(contactUsRes.data.message);
      setIsContactUSLoading(false);
      reset();
    } catch (error) {
      setIsGetinTouchError(true);
      setConstactUsMessage(error.message ?? "Something went wrong!");
      setIsContactUSLoading(false);
    }
  };

  const getAllPlans = async () => {
    const kidId = "3797c2b5-cee4-4b98-8a94-2dd07cdf25bf";
    const plansRes = await getPlansWithoutLogin(kidId, "");
    console.log("plans-re", plansRes?.data?.entity?.plans);
    const plans = plansRes?.data?.entity?.plans;
    setAllPlans(plans);
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  useEffect(() => {
    console.log(activePlanType, "active plan type");
    const searchText = plansTab.find(
      (type) => String(type.label).toLowerCase() === activePlanType
    )?.search;
    console.log(searchText, "search text");
    const tempActiveTypePlans =
      allPlans?.filter(
        (plan) =>
          activePlanType == "all" ||
          !!String(plan.name)
            .toLowerCase()
            .includes(String(searchText).toLowerCase())
      ) ?? [];
    setActiveTypePlans(tempActiveTypePlans);
  }, [activePlanType, allPlans]);

  const plansSwiper = useMemo(() => {
    console.log(activeTypePlans, "active plans");

    return activeTypePlans?.length ? (
      activeTypePlans.map((plan) => (
        <>
          <SwiperSlide className="h-full">
            <div className="h-[490px]  relative flex flex-col py-6 px-5 bg-white rounded-lg">
              {plan.isMostPopular && (
                <img
                  className="top-0 left-4 absolute -translate-y-2 lg:-translate-y-2.5 w-24 lg:w-32"
                  src="images/most-popular-tag.svg"
                  alt=""
                />
              )}
              {plan.planSavings && (
                <div className="mb-6 bg-[#ebe0ff] px-5 py-1.5 lg:px-8 lg:py-3 ml-auto rounded-full text-xs lg:text-sm font-semibold">
                  20% Save
                </div>
              )}
              <div className="mb-6">
                <span className="text-[22px] lg:text-4xl text-[#F93360] font-bold">
                  ₹{plan.PlanSettings[0].price}
                </span>
                <span className="text-xs lg:text-sm text-[#9e9e9e] font-medium">
                  /{plan.PlanSettings[0].days} Day
                </span>
              </div>
              <div className="font-semibold text-base lg:text-lg h-[100px]">
                {plan.name}
              </div>
              <div className="mb-6 text-xs lg:text-sm text-[#6e6e6e] h-[300px]">
                {/* {plan.description} */}
                {plan.description
                  .replace("⦿", "")
                  .split("\n")
                  .map((descriptionPoint) => {
                    return (
                      <>
                        <div className="flex gap-4 items-center mb-4">
                          <img
                            className="w-5 lg:w-6"
                            src="images/round-tick-sign.svg"
                            alt=""
                          />
                          <p className="text-xs lg:text-sm text-[#6e6e6e]">
                            {descriptionPoint.replace("⦿", "")}
                          </p>
                        </div>
                      </>
                    );
                  })}
              </div>
              {/* <div className='mb-6'>
              <div className='flex gap-4 items-center mb-4'>
                <img className='w-5 lg:w-6' src="images/round-tick-sign.svg" alt="" />
                <p className='text-xs lg:text-sm text-[#6e6e6e]'>Choose from 3 premimum lunch options for 10 days</p>
              </div>
              <div className='flex gap-4 items-center mb-4'>
                <img className='w-5 lg:w-6' src="images/round-tick-sign.svg" alt="" />
                <p className='text-xs lg:text-sm text-[#6e6e6e]'>Add breakfast & snack options with launch</p>
              </div>
              <div className='flex gap-4 items-center mb-4'>
                <img className='w-5 lg:w-6' src="images/round-tick-sign.svg" alt="" />
                <p className='text-xs lg:text-sm text-[#6e6e6e]'>Flexibility to choose from variety of lunch options</p>
              </div>
            </div> */}
              <button
                onClick={() => redirectTo("/login")}
                className="w-full max-h-[48px] min-h-[48px] lg:max-h-[56px] lg:min-h-[56px] group "
              >
                <div className="w-full h-full    p-[2px]   rounded-full">
                  <div className="w-full flex items-center justify-center whitespace-nowrap  hover:text-white text-[#2E2E2E] text-sm lg:text-base font-medium h-full  ease-linear duration-500  bg:white  hover:bg-[#029769] border-[2px] border-[#F8F8F8] rounded-full">
                    Subscribe
                  </div>
                </div>
              </button>
            </div>
          </SwiperSlide>
        </>
      ))
    ) : (
      <p className="w-full text-center">No plan found for selected type!</p>
    );
  }, [activePlanType, activeTypePlans]);
  const handleLogin = (e) => {
    const auth = getAuth();
    if (e) e.preventDefault();
    if (authCookie && auth && auth.roles.includes("Parent B2B2C")) {
      router.push(`/school-b2b2c/${auth.school.id}/menus`);
    } else if (authCookie) {
      router.push("/children");
    } else {
      router.push("/login");
    }
  };

  const redirectTo = (route) => {
    router.push(route);
  };

  const replaceRoute = (route) => {
    router.replace(route);
  };

  const [showNav, setShowNav] = useState(false);

  const onMoboClicks = () => {
    setShowNav(!showNav);
  };
  const closeNav = () => {
    setShowNav(false);
  };

  const menuData = [
    {
      id: 1,
      menuName: "RAVA IDLI - SAMBAR",
      menuDescription:
        "Soft, fluffy rava idlis, made with semolina and tempered with mustard seeds and curry leaves, are served alongside a hearty, aromatic sambhar, rich with lentils and a medley of vegetables. This combination promises a delicious and comforting meal.",
      price: 75,
      mealType: "Breakfast",
      menuImage:
        "images/idaliii.jpeg",
    },
    {
      id: 2,
      menuName: "GHEE RICE - CHANA DAL",
      menuDescription:
        "Aromatic rice cooked with fragrant ghee pairs perfectly with the hearty and protein-rich chana dal, seasoned with traditional spices. This combination offers a delightful blend of textures and flavors.",
      price: 125,
      mealType: "Lunch",
      menuImage:
      "images/ghee-rice.jpeg",
    },
    {
      id: 3,
      menuName: "PANEER MASALA PAV + TIL KE LADOO",
      menuDescription:
        "Savor the spicy, aromatic paneer masala, served on soft, buttery pav rolls, creating a savory, satisfying experience. Complement this with sweet, nutty til ke ladoo, made from sesame seeds and jaggery, adding a delightful end to your meal.",
      price: 90,
      mealType: "Snacks",
      menuImage:
      "images/paneer-pav.jpeg",
    },
  ];
  const menuData2 = [
    {
      id: 1,
      menuName: "CORN & CHEESE PARATHA - PICKLE",
      menuDescription:
        "A delightful twist on a classic favorite. The paratha, stuffed with a creamy blend of sweet corn and melted cheese, is perfectly crisped on the tawa. Accompanied by a tangy, spicy pickle, this dish promises a burst of flavors that is both comforting and exciting.",
      price: 125,
      mealType: "Breakfast",
      menuImage:
      "images/paratha.jpg",
    },
    {
      id: 2,
      menuName: "TAWA ROTI - PALAK PANEER",
      menuDescription:
        "A nutritious and flavorful combination of soft, freshly made tawa rotis with creamy palak paneer, where tender paneer cubes are simmered in a rich spinach gravy, seasoned with aromatic Indian spices. ",
      price: 165,
      mealType: "Lunch",
      menuImage:
      "images/roti.jpeg",
    },
    {
      id: 3,
      menuName: "BAKED RAGI FLAX BISCUITS + RAVA LADOO",
      menuDescription:
        "Savor the nutty, wholesome crunch of baked ragi and flax biscuits, paired with a vibrant, tangy salsa for a savory kick. Finish off with the sweet, golden allure of rava ladoo, creating a perfectly balanced medley of flavors and textures!",
      price: 90,
      mealType: "Snacks",
      menuImage:
      "images/ladoo.jpeg",
    },
  ];

  const contactUsFormChanges = () => {
    setConstactUsMessage(null);
    setIsGetinTouchError(false);
  };
  return (
    <div className="mx-auto overflow-hidden app-v3-landingpage">
      {/* header section */}
      <div className="w-full py-4">
        <div className="container mx-auto px-4 lg:px-10 xl:px-12 lg:p-[10px] w-full flex items-center justify-between">
          <img
            src="images/MonkeyBox-logo-150x150.webp"
            className="md:h-[80px] md:w-[80px] h-10 w-10"
            alt="mblogo"
          />
          <div className="hidden md:w-[86%] w-full md:justify-evenly md:flex flex-col md:flex-row md:items-center md:pl-[10px]">
            <ul className="flex flex-wrap justify-center md:justify-start items-center font-semibold text-black md:text-base text-[8px]">
              <li
                className="px-[13px] py-[10px] text-base relative  hover:text-[#029769] transition-all ease-in-out duration-200 cursor-pointer text-[22px] "
                onClick={() => scrollToDiv("planSection")}
              >
                Plan
              </li>
              {/* <li
                className="px-[13px] py-[10px] text-base relative hover:text-[#029769]  transition-all ease-in-out duration-200 cursor-pointer  text-[22px] "
                onClick={() => scrollToDiv("process")}
              >
                Our School
              </li> */}
              <li
                className="px-[13px] py-[10px] text-base relative hover:text-[#029769]  transition-all ease-in-out duration-200 cursor-pointer  text-[22px] "
                onClick={() => scrollToDiv("getInTouchSection")}
              >
                Get In Touch
              </li>
              <li
                className="px-[13px] py-[10px] text-base relative hover:text-[#029769]  transition-all ease-in-out duration-200 cursor-pointer  text-[22px] "
                onClick={() => scrollToDiv("getAppSection")}
              >
                Get App
              </li>
            </ul>
          </div>
          {/* <button>
            <div className='drop-shadow-md p-[2px] lg:p-[3px] bg-[#1C808A] block rounded-full'>
              <div className='whitespace-nowrap px-6 lg:px-12 text-sm lg:text-base font-semibold text-white py-1 lg:py-3 !bg-[#029769] border-l-2 border-r-2 border-t-2 border-white rounded-full'>
                Log in
              </div>
            </div>
          </button> */}
          <div className="flex gap-2 justify-center items-center">
            <button
              onClick={() => redirectTo("/login")}
              className="ml-auto w-[100px] lg:w-[110px] h-[38px] lg:h-[56px]  drop-shadow-loginButtonDropShadow shadow-loginButtonShadow p-[2px] bg-[#1C808A]  rounded-full cursor-pointer   hover:border-black  ease-linear duration-500"
            >
              <div className="w-full  flex items-center justify-center whitespace-nowrap text-white text-sm lg:text-base font-semibold h-full hover:bg-black ease-linear duration-500 bg-[#029769] border-l-[2px] border-r-[2px] border-t-[2px] border-white rounded-full ">
                Log In
              </div>
            </button>
            <div
              className="md:hidden flex  cursor-pointer"
              onClick={onMoboClicks}
            >
              {showNav ? (
                <AiOutlineCloseCircle
                  size={24}
                  color="black
                  "
                />
              ) : (
                <AiOutlineMenu
                  size={24}
                  color="
                  black"
                />
              )}
            </div>
          </div>

          {/*hem menu start*/}
          <div
            className={
              showNav
                ? "fixed top-[5rem] w-full left-0 bg-white   ease-in-out duration-500 z-[1000]"
                : "fixed top-[-100%]"
            }
          >
            <ul
              className="flex flex-col justify-center md:justify-start items-center font-semibold text-black md:text-base text-[8px]"
              onClick={closeNav}
            >
              <li
                className="px-[13px] py-[10px] text-base relative  hover:text-[#029769] transition-all ease-in-out duration-200 cursor-pointer"
                onClick={() => scrollToDiv("planSection")}
              >
                Plan
              </li>
              <li
                className="px-[13px] py-[10px] text-base relative hover:text-[#029769]  transition-all ease-in-out duration-200 cursor-pointer"
                onClick={() => scrollToDiv("process")}
              >
                Our School
              </li>
              <li
                className="px-[13px] py-[10px] text-base relative hover:text-[#029769]  transition-all ease-in-out duration-200 cursor-pointer"
                onClick={() => scrollToDiv("getInTouchSection")}
              >
                Get In Touch
              </li>
              <li
                className="px-[13px] py-[10px] text-base relative hover:text-[#029769]  transition-all ease-in-out duration-200 cursor-pointer"
                onClick={() => scrollToDiv("getAppSection")}
              >
                Get App
              </li>
            </ul>
          </div>
          {/* hem menu ends */}
        </div>
      </div>
      {/* banner section */}

      <div className=" w-full relative bg-[#f8f8f8]  ">
        {/* <img className='hidden lg:block -z-1 absolute top-0 right-0 h-full' src="images/landingBanner.png" alt="" /> */}
        <img
          className="hidden xl:flex -z-1 absolute top-0 right-0   h-full"
          src="images/right-home.png"
          alt=""
        />
        <div className="relative bg-[#f8f8f8] lg:bg-[#ffffff00]">
          <div className="container mx-auto top-0 left-0 pt-12 pb-16 lg:pt-32 lg:pb-40 z-10 px-5 lg:px-10 xl:px-12">
            <div className="text-base lg:text-lg mb-4 font-semibold relative before:absolute before:top-1/2 before:left-1 before:transform before:-translate-y-1/2 before:w-8 before:h-1 before:bg-[#2E2E2E] text-[#2E2E2E] pl-12">
              The Best Meal Serving Mobile App
            </div>
            <h1 className="text-[26px] xl:text-[58px] lg:leading-[73px] font-bold mb-5">
              <span className="block text-[#2e2e2e]">
                {" "}
                Serving up smiles with{" "}
              </span>
              <span className="block text-[#029769]">
                balanced, {""} {""} {""} {""} delicious meals!
              </span>
              <img
                className="lg:w-96 mt-3"
                src="images/heading-underline-green.svg"
                alt=""
              />
            </h1>
            <p className="text-sm lg:text-base">
              At MonkeyBox, our vision is for every school-going kid to eat
              warm, healthy <br />
              and nutritious meals twice a day. 
            </p>
            {/* <p className='text-sm lg:text-base'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
            <div className="mt-8 flex items-start justify-start gap-5">
              <Link href={playStoreLink}>
                <img
                  className="w-[167.04px] h-[48px] object-cover cursor-pointer"
                  src="images/googlePlay.png"
                  alt=""
                />
              </Link>
              <Link href={appStoreLink}>
                <img
                  className="w-[167.04px] h-[48px] object-cover cursor-pointer"
                  src="images/PlayStoreMB.png"
                  alt=""
                />
              </Link>
            </div>
            <div className="flex mt-6">
              <div className="flex">
                <img
                  className="w-10 lg:w-12"
                  src="images/download-user4.png"
                  alt=""
                />
                <img
                  className="w-10 lg:w-12 -translate-x-1/3"
                  src="images/download-user3.png"
                  alt=""
                />
                <img
                  className="w-10 lg:w-12 -translate-x-2/3"
                  src="images/download-user2.png"
                  alt=""
                />
                <img
                  className="w-10 lg:w-12 -translate-x-full"
                  src="images/download-user1.png"
                  alt=""
                />
                <span className="w-10 lg:w-12 translate-x-[-133%] h-10 lg:h-12 font-base rounded-full flex items-center justify-center text-2xl leading-1 lg:text-4xl text-[#8E8E8E] bg-[#eee]">
                  <img src={"images/add.svg"} alt="" />
                  {/* <span>+</span> */}
                </span>
              </div>
              <div className="-translate-x-1/3">
                <p className="text-base lg:text-lg font-semibold">50K+</p>
                <p className="text-xs">Worldwide downloads</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* download section */}
      <div className="w-full flex flex-col lg:flex-row gap-10 lg:gap-0 items-start justify-between container mx-auto py-12 lg:py-16 px-5 lg:px-10 xl:px-12">
        <div className=" lg:w-5/12">
          <div className="mb-6">
            <p className="mb-2 text-2xl lg:text-4xl lg:leading-[50px] font-bold">
              Download Our{" "}
              <span className="text-[#029769]"> Meal Serving app today!</span>
            </p>
            <img src="images/heading-underline-green.svg" alt="" />
          </div>
          <p className="text-sm font-normal text-[#6e6e6e] mb-4">
            Our mission is to take away every parent’s worries about planning
            balanced meals for their children and deciding what to pack in their
            boxes every day!
          </p>
          <div className="flex items-center justify-between">
            <div className="">
              <p className="text-[#029769] text-xl lg:text-2xl font-semibold">
                80+
              </p>
              <p className="text-[#6e6e6e] text-xs">
                Schools across Bangalore have partnered with us
              </p>
            </div>
            <div className="mx-5 w-1 h-8 bg-[#ddd]"></div>
            <div className="">
              <p className="text-[#029769] text-xl lg:text-2xl font-semibold">
                25000+
              </p>
              <p className="text-[#6e6e6e] text-xs">
                Families trust us for hassle-free meals for their kids
              </p>
            </div>
            <div className="mx-5 w-1 h-8 bg-[#ddd]"></div>
            <div className="">
              <p className="text-[#029769] text-xl lg:text-2xl font-semibold">
                5,00,000+
              </p>
              <p className="text-[#6e6e6e] text-xs">
                Meals served while spreading countless smiles
              </p>
            </div>
          </div>
        </div>
        <div className=" lg:5/12 flex items-start justify-end gap-6 mx-auto lg:mx-0">
          <div className="relative rounded-xl bg-[#f2f2f2] p-4 min-w-[150px] lg:min-w-[230px]">
            <p className="mb-1 font-bold text-lg lg:text-2xl">For android</p>
            <p className="mb-4 text-sm lg:text-base">Android 9.0+</p>
            <button
              onClick={() => replaceRoute(playStoreLink)}
              className="mb-6 ml-auto w-[120px] lg:w-[139px] h-[35px] lg:h-[48px]  drop-shadow-loginButtonDropShadow shadow-loginButtonShadow p-[2px] bg-[#1C808A] hover:bg-black ease-linear duration-500 rounded-full "
            >
              <div className="w-full flex items-center justify-center whitespace-nowrap text-white text-xs lg:text-sm font-semibold h-full  hover:bg-black ease-linear duration-500 bg-[#029769] border-l-[2px] border-r-[2px] border-t-[2px] border-white rounded-full">
                Download App
              </div>
            </button>
            <img
              className="!w-20 lg:w-auto"
              src="images/playstore-link-qr.svg"
              alt=""
            />
            <span className="rounded-full p-4 lg:p-6 absolute bottom-0 right-0 translate-x-[20%] translate-y-[20%] bg-white">
              <img
                className="w-6 lg:w-auto"
                src="images/playstore-logoIcon.svg"
                alt=""
              />
            </span>
          </div>
          <div className="relative rounded-xl bg-[#f2f2f2] p-4 min-w-[150px] lg:min-w-[230px]">
            <p className="mb-1 font-bold text-lg lg:text-2xl">For ios</p>
            <p className="mb-4 text-sm lg:text-base text-[#4e4e4e]">
              IOS 15.8+
            </p>
            <button
              onClick={() => replaceRoute(appStoreLink)}
              className="mb-6 ml-auto w-[120px] lg:w-[139px] h-[35px] lg:h-[48px]  drop-shadow-loginButtonDropShadow shadow-loginButtonShadow p-[2px] bg-[#1C808A] hover:bg-black ease-linear duration-500 rounded-full"
            >
              <div className="w-full flex items-center justify-center whitespace-nowrap text-white text-xs lg:text-sm font-semibold h-full  hover:bg-black ease-linear duration-500 bg-[#029769] border-l-[2px] border-r-[2px] border-t-[2px] border-white rounded-full">
                Download App
              </div>
            </button>
            <img
              className="!w-20 lg:w-auto"
              src="images/appstore-link-qr.svg"
              alt=""
            />
            <span className="rounded-full p-4 lg:p-6 absolute bottom-0 right-0 translate-x-[20%] translate-y-[20%] bg-white">
              <img
                className="w-6 lg:w-auto"
                src="images/appleLogo.svg"
                alt=""
              />
            </span>
          </div>
        </div>
      </div>

      {/* how it works section */}
      <div className="w-full relative bg-[#ebe0ff] py-16 lg:py-24">
        <img
          className="absolute top-0 right-0 z-10 w-16 lg:w-28"
          src="images/howit-works-section-corner.svg"
          alt=""
        />
        <div className="container mx-auto px-5 xl:px-20">
          <div className="flex flex-col items-center mb-12">
            <h1 className="mb-3 text-[24px] lg:text-[40px] font-semibold">
              How It Works
            </h1>
            <img src="images/heading-underline.svg" alt="" />
          </div>
          <div className="lg:px-28">
            <div className=" p-4 bg-white rounded-xl shadow-howItWorksSectionShadow">
              <iframe
                className="w-full h-xl rounded-xl"
                height={500}
                src="https://www.youtube.com/embed/Ux7KgNKGsYM?si=b9-dWcH5n8Bm_LHA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* menus section */}
      <div className="w-full relative bg-white py-20 lg:py-24">
        <img
          className="absolute top-0 left-0 z-10 w-28 lg:w-40"
          src="images/menus-section-corner.svg"
          alt=""
        />
        <div className="container mx-auto px-5 lg:px-12 xl:px-12">
          <div className="flex flex-col items-center mb-12">
            <h1 className="mb-3 text-[24px] lg:text-[40px] font-semibold">
              This Week's Menu
            </h1>
            <img src="images/heading-underline.svg" alt="" />
          </div>
          <div className="flex flex-col lg:flex-row gap-8 lg:gap-10">
            {menuData &&
              menuData.map((item, index) => {
                return (
                  <div className="lg:w-1/3 flex flex-col relative rounded-xl shadow-menuCardShadow">
                    <img
                      className="rounded-t-md w-full h-[250px] rounded-t-md object-cover"
                      src={item.menuImage}
                      alt=""
                    />
                    <div className="absolute top-3 left-3">
                      <div className="flex justify-center items-center text-center bg-[#fff] w-[100px] h-[30px] rounded-[22px] text-[12px] text-black font-semibold">
                        {item.mealType}
                      </div>
                    </div>
                    <div className="pt-4 pb-6 px-4 rounded-b-md">
                      <div className="flex gap-1 mb-4 h-[20px]">
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                      </div>
                      <p className="mb-3 text-lg lg:text-2xl font-bold h-[50px]">
                        {item.menuName}
                      </p>
                      <p className="mb-5 text-sm lg:text-base text-[#6E6E6E] mt-4 h-[120px] lg:h-[180px] xl:h-[120px]">
                        {item.menuDescription}
                      </p>
                      <div className="flex justify-between items-center rounded-b-md mt-[40px] h-[50px]">
                        <p className="text-lg lg:text-2xl font-bold text-[#F93360]">
                          ₹ {item.price}
                        </p>
                        <div className="ml-auto mr-2 bg-[#eee] w-11 h-11 rounded-full flex items-center justify-center">
                          <img src="images/heart-line.svg" alt="" />
                        </div>
                        <button
                          onClick={() => redirectTo("login")}
                          className="w-24 h-11 group"
                        >
                          <span className="group-hover:hidden border-2 border-[#f8f8f8] ease-linear duration-500 w-full h-full text-xs font-medium flex items-center justify-center rounded-full">
                            Order Now
                          </span>
                          <div className="w-full h-full hidden group-hover:block  drop-shadow-md p-[1px] bg-[#1C808A]  rounded-full shadow-loginButtonShadow">
                            <div className="w-full flex items-center justify-center whitespace-nowrap  text-white text-xs font-medium h-full   ease-linear duration-500 !bg-[#029769] border-l-[2px] border-r-[2px] border-t-[2px] border-white rounded-full">
                              Log in
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="flex flex-col lg:flex-row mt-[50px] gap-8 lg:gap-10">
            {menuData2 &&
              menuData2.map((item, index) => {
                return (
                  <div className="lg:w-1/3 flex flex-col relative rounded-xl shadow-menuCardShadow">
                    <img
                      className="rounded-t-md w-full h-[250px] rounded-t-md object-cover"
                      src={item.menuImage}
                      alt=""
                    />
                    <div className="absolute top-3 left-3">
                      <div className="flex justify-center items-center text-center bg-[#fff] w-[100px] h-[30px] rounded-[22px] text-[12px] text-black font-semibold">
                        {item.mealType}
                      </div>
                    </div>
                    <div className="pt-4 pb-6 px-4 rounded-b-md">
                      <div className="flex gap-1 mb-4 h-[20px]">
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                        <img
                          className="w-4"
                          src="images/star-yellow.svg"
                          alt=""
                        />
                      </div>
                      <p className="mb-3 text-lg lg:text-2xl font-bold h-[50px]">
                        {item.menuName}
                      </p>
                      <p className="mb-5 text-sm lg:text-base text-[#6E6E6E] mt-4 h-[120px] lg:h-[180px] xl:h-[120px]">
                        {item.menuDescription}
                      </p>
                      <div className="flex justify-between items-center rounded-b-md mt-[40px] h-[50px]">
                        <p className="text-lg lg:text-2xl font-bold text-[#F93360]">
                          ₹ {item.price}
                        </p>
                        <div className="ml-auto mr-2 bg-[#eee] w-11 h-11 rounded-full flex items-center justify-center">
                          <img src="images/heart-line.svg" alt="" />
                        </div>
                        <button
                          onClick={() => redirectTo("login")}
                          className="w-24 h-11 group"
                        >
                          <span className="group-hover:hidden border-2 border-[#f8f8f8] ease-linear duration-500 w-full h-full text-xs font-medium flex items-center justify-center rounded-full">
                            Order Now
                          </span>
                          <div className="w-full h-full hidden group-hover:block  drop-shadow-md p-[1px] bg-[#1C808A]  rounded-full shadow-loginButtonShadow">
                            <div className="w-full flex items-center justify-center whitespace-nowrap  text-white text-xs font-medium h-full   ease-linear duration-500 !bg-[#029769] border-l-[2px] border-r-[2px] border-t-[2px] border-white rounded-full">
                              Log in
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {/* subscription section */}
      <div
        className="w-full relative bg-[#f8f8f8] py-16 lg:py-24"
        id="planSection"
      >
        <img
          className="absolute top-0 right-0 z-10 w-16 lg:w-28"
          src="images/plan-section-corner.svg"
          alt=""
        />
        <div className="container mx-auto px-5 lg:px-10 xl:px-12">
          <div className="flex flex-col items-center mb-12">
            <h1 className="mb-3 text-[24px] lg:text-[40px] font-semibold">
              Choose Your Plan
            </h1>
            <img src="images/heading-underline.svg" alt="" />
          </div>
          <div className="custom-scrollbar overflow-x-scroll lg:overflow-x-hidden mb-4 lg:mb-10 max-w-max mx-auto bg-white rounded-full py-2 px-3 lg:p-3 flex items-center justify-start gap-3">
            {plansTab.map((plan) => (
              <button
                key={plan.id}
                onClick={() => setActivePlanType(plan.label.toLowerCase())}
                className={`whitespace-nowrap px-5 py-1.5 lg:px-7 lg:py-2.5 text-sm lg:text-base font-semibold rounded-full ${
                  plan.label.toLowerCase() === activePlanType
                    ? "text-white bg-[#029769]"
                    : ""
                }`}
              >
                {plan.label}
              </button>
            ))}
          </div>
          <div className="plan-swieper w-auto overflow-scroll">
            <Swiper
              className="mySwiper mx-auto px-24 !pt-8"
              spaceBetween={20}
              breakpoints={{
                default: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 30,
                },
                960: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1120: {
                  slidesPerView: 2.5,
                  spaceBetween: 40,
                },
                1400: {
                  slidesPerView: 3.2,
                  spaceBetween: 40,
                },
              }}
              slidesPerView={1.2}
              draggable={true}
              direction="horizontal"
              scrollbar={{ draggable: true }}
            >
              {/* {
                !!activeTypePlans.length
                && activeTypePlans.map(plan => (<>
                    <SwiperSlide className="h-full">
                      <div className='h-[490px]  relative flex flex-col py-6 px-5 bg-white rounded-lg'>
                        {
                          plan.isMostPopular &&
                          <img className='top-0 left-4 absolute -translate-y-2 lg:-translate-y-2.5 w-24 lg:w-32' src="images/most-popular-tag.svg" alt="" />
                        }
                        {
                          plan.planSavings &&
                          <div className='mb-6 bg-[#ebe0ff] px-5 py-1.5 lg:px-8 lg:py-3 ml-auto rounded-full text-xs lg:text-sm font-semibold'>20% Save</div>
                        }
                        <div className='mb-6'>
                          <span className='text-[22px] lg:text-4xl text-[#F93360] font-bold'>₹{plan.PlanSettings[0].price}</span>
                          <span className='text-xs lg:text-sm text-[#9e9e9e] font-medium'>/{plan.PlanSettings[0].days} Day</span>
                        </div>
                        <div className='font-semibold text-base lg:text-lg h-[100px]'>{plan.name}</div>
                        <div className='mb-6 text-xs lg:text-sm text-[#6e6e6e] h-[300px]'>
                          {plan.description.replace("⦿", "").split('\n').map(descriptionPoint => {
                            return (<>
                              <div className='flex gap-4 items-center mb-4'>
                                <img className='w-5 lg:w-6' src="images/round-tick-sign.svg" alt="" />
                                <p className='text-xs lg:text-sm text-[#6e6e6e]'>{descriptionPoint.replace("⦿", "")}</p>
                              </div>
                            </>)
                          })}
                        </div>
                        <button onClick={() => redirectTo("/login")} className='w-full max-h-[48px] min-h-[48px] lg:max-h-[56px] lg:min-h-[56px] group '>
                         
                          <div className='w-full h-full    p-[2px]   rounded-full'>
                            <div className='w-full flex items-center justify-center whitespace-nowrap  hover:text-white text-[#2E2E2E] text-sm lg:text-base font-medium h-full  ease-linear duration-500  bg:white  hover:bg-[#029769] border-[2px] border-[#F8F8F8] rounded-full'>
                              Subscribe
                            </div>
                          </div>
                        </button>
                      </div>
                    </SwiperSlide>
                  </>)
                ) 
              } */}
              {plansSwiper}
            </Swiper>
            {/* {
              !activeTypePlans.length && 
              <p className='w-full text-center'>No plan found for selected type!</p>
            } */}
          </div>
        </div>
      </div>

      {/* what is monkeybix section */}
      <div className="w-full relative bg-[#fff8e5] py-16 lg:py-24">
        <img
          className="absolute top-0 left-0 z-10 w-24 lg:w-36"
          src="images/whatis-monkeybox-section-corner.svg"
          alt=""
        />
        <div className="container lg:grid grid-cols-7 items-center mx-auto px-12 lg:px-10 xl:px-12 lg:gap-8">
          <div className=" col-span-4  mb-20 lg:mb-0">
            <div className="flex flex-col items-start mb-6 lg:mb-6">
              <h1 className="mb-3 text-[24px] lg:text-[40px] font-semibold">
                What Is MonkeyBox?
              </h1>
              <img src="images/heading-underline.svg" alt="" />
            </div>
            <p className="text-[#6e6e6e] mb-12 lg:mb-20 text-sm lg:test-base">
              MonkeyBox is a revolutionary tiffin service designed to reduce
              your stress of planning & preparing a balanced meal for your child
              every single day. We aim to keep your child healthy & happy with
              nutritious, variety-filled meals delivered straight to school
              every day of the week!
            </p>
            <a href="/MB_Brochure.pdf" download="MB_Brochure.pdf">
              <button className="border-2 hover:border-[#fff] border-[#2e2e2e] hover:text-white  text-[#2e2e2e] w-40 h-10 text-base font-medium flex items-center justify-center rounded-full hover:bg-[#029769]  ease-linear duration-500">
                Discover Now
              </button>
            </a>
          </div>
          {/* <div className='hidden lg:block'></div> */}
          <div className="mx-auto lg:ml-auto mt-10 lg:mt-0 flex justify-center  lg:justify-end  choose-plan-swiper col-span-3 ">
            <div className="max-w-[300px] lg:max-w-[350px] mx-auto lg:ml-auto">
              <Swiper
                className="mySwiper mx-auto pl-6"
                spaceBetween={40}
                slidesPerView={1}
                draggable={true}
                direction="horizontal"
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                modules={[Pagination]}
              >
                <SwiperSlide className="mx-auto pr-5">
                  <div key={1} className="relative w-full rounded-lg">
                    <iframe
                      className="w-[280px] lg:w-[330px] rounded-xl mx-auto"
                      // title={`video-hs3C071A_Yk`}
                      height="400"
                      src={`https://www.youtube.com/embed/hs3C071A_Yk`}
                      frameBorder="0"
                    ></iframe>
                    <p className="text-center text-base font-semibold min-w-max mt-5">
                      Hear It From MonkeyBox Parents
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="mx-auto">
                  <div key={1} className="relative w-full rounded-lg">
                    <iframe
                      className="w-[280px] lg:w-[330px] rounded-xl mx-auto"
                      title={`video-hs3C071A_Yk`}
                      height="400"
                      // width="330"
                      src={`https://www.youtube.com/embed/hs3C071A_Yk`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                    <p className="text-center text-base font-semibold min-w-max mt-5">
                      Hear It From MonkeyBox Parents
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* food and quality sections */}
      <div className="w-full relative bg-[#f3faf7] py-16 lg:py-24">
        <img
          className="absolute top-0 right-0 z-10 w-28 lg:w-40"
          src="images/quality-section-corner.svg"
          alt=""
        />
        <div className="container mx-auto px-5 lg:px-10 xl:px-12 gap-8">
          <div className="mb-8">
            <div className="flex flex-col items-center mb-12">
              <h2 className="mb-3 text-2xl lg:text-[40px] font-semibold">
                Food Quality & Safety
              </h2>
              <img src="images/heading-underline.svg" alt="" />
            </div>
          </div>
          <div className="relative">
            <div className="absolute top-0 left-1/2 -translate-x-1/2 h-[330px] lg:h-[420px] w-[70vw] lg:w-[60vw] bg-[#e0f2ec] rounded-lg"></div>
            <div className="food-quality-swiper relative flex gap-6 z-10 pt-10 pb-16 lg:pb-32">
              <Swiper
                className="food-quality-swiper pb-40"
                direction="horizontal"
                slidesPerView={1}
                spaceBetween={50}
                breakpoints={{
                  default: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 1.5,
                    spaceBetween: 30,
                  },
                  960: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                // navigation
                // pagination
                pagination={{ clickable: true }}
                modules={[Pagination]}
              >
                <SwiperSlide>
                  <div className="lg:h-[300px] rounded-lg flex flex-col px-5 py-7 bg-white shadow-qualityCardShadow">
                    <div className="mb-3 w-16 h-16 lg:w-20 lg:h-20 flex items-center justify-center bg-[#029769] rounded-full">
                      <img
                        className="w-8 lg:w-auto"
                        src="images/quality-icon1.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <p className="text-lg lg:text-xl mb-2 text-black font-semibold">
                      Nutrition, Made Delectable
                    </p>
                    <p className="text-sm lg:text-base text-[#6e6e6e] mb-2">
                      Planned by nutritionists, crafted by 5-star chefs – only
                      the yummiest, healthiest food makes it into a MonkeyBox.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lg:h-[300px] rounded-lg flex flex-col px-5 py-7 bg-white shadow-qualityCardShadow">
                    <div className="mb-3 w-16 h-16 lg:w-20 lg:h-20 flex items-center justify-center bg-[#029769] rounded-full">
                      <img
                        className="w-8 lg:w-auto"
                        src="images/quality-icon1.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <p className="text-lg lg:text-xl mb-2 text-black font-semibold">
                      Naturally Fresh
                    </p>
                    <p className="text-sm lg:text-base text-[#6e6e6e] mb-2">
                      All our veggies & staples are farm sourced, and meals are
                      freshly cooked in ultra modern kitchens, daily.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lg:h-[300px] rounded-lg flex flex-col px-5 py-7 bg-white shadow-qualityCardShadow">
                    <div className="mb-3 w-16 h-16 lg:w-20 lg:h-20 flex items-center justify-center bg-[#029769] rounded-full">
                      <img
                        className="w-8 lg:w-auto"
                        src="images/quality-icon1.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <p className="text-lg lg:text-xl mb-2 text-black font-semibold">
                      Healthy weight, healthy children
                    </p>
                    <p className="text-sm lg:text-base text-[#6e6e6e] mb-2">
                      53% of kids in metros have unhealthy BMI’s. Which is why,
                      every MonkeyBox is planned to help your child maintain a
                      healthy body weight for his/her age.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lg:h-[300px] rounded-lg flex flex-col px-5 py-7 bg-white shadow-qualityCardShadow">
                    <div className="mb-3 w-16 h-16 lg:w-20 lg:h-20 flex items-center justify-center bg-[#029769] rounded-full">
                      <img
                        className="w-8 lg:w-auto"
                        src="images/quality-icon1.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <p className="text-lg lg:text-xl mb-2 text-black font-semibold">
                      Nutrition, Made Delectable
                    </p>
                    <p className="text-sm lg:text-base text-[#6e6e6e] mb-2">
                      Planned by nutritionists, crafted by 5-star chefs – only
                      the yummiest, healthiest food makes it into a MonkeyBox.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* <div className='relative flex gap-6 z-10 pt-16 pb-32'>
              <div className='rounded-lg w-1/3 flex flex-col px-5 py-7 bg-white shadow-qualityCardShadow'>
                <div className='mb-3 w-20 h-20 flex items-center justify-center bg-[#029769] rounded-full'>
                  <img src="images/quality-icon1.svg" alt="" srcset="" />
                </div>
                <p className='text-xl mb-2 text-black font-semibold'>Nutrition, Made Delectable</p>
                <p className='text-sm text-[#6e6e6e] mb-2'>Planned by nutritionists, crafted by 5-star chefs – only the yummiest, healthiest food makes it into a MonkeyBox.</p>
              </div>
              <div className='rounded-lg w-1/3 flex flex-col px-5 py-7 bg-white shadow-qualityCardShadow'>
                <div className='mb-3 w-20 h-20 flex items-center justify-center bg-[#029769] rounded-full'>
                  <img src="images/quality-icon1.svg" alt="" srcset="" />
                </div>
                <p className='text-xl mb-2 text-black font-semibold'>Nutrition, Made Delectable</p>
                <p className='text-sm text-[#6e6e6e] mb-2'>Planned by nutritionists, crafted by 5-star chefs – only the yummiest, healthiest food makes it into a MonkeyBox.</p>
              </div>
              <div className='rounded-lg w-1/3 flex flex-col px-5 py-7 bg-white shadow-qualityCardShadow'>
                <div className='mb-3 w-20 h-20 flex items-center justify-center bg-[#029769] rounded-full'>
                  <img src="images/quality-icon1.svg" alt="" srcset="" />
                </div>
                <p className='text-xl mb-2 text-black font-semibold'>Nutrition, Made Delectable</p>
                <p className='text-sm text-[#6e6e6e] mb-2'>Planned by nutritionists, crafted by 5-star chefs – only the yummiest, healthiest food makes it into a MonkeyBox.</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div
        className="w-full relative bg-[#fdf3f4] py-16 lg:py-24"
        id="getAppSection"
      >
        <img
          className="absolute top-0 left-0 z-10 w-36 lg:w-60"
          src="images/monkeybox-section-corner.svg"
          alt=""
        />
        <img
          className="absolute bottom-0 lg:top-0 translate-x-1/2 right-1/2 lg:right-0 z-5 w-96 lg:w-auto"
          src="images/monkeyboxapp-section-bg.svg"
          alt=""
        />

        <div className="relative z-10 container mx-auto px-5 lg:px-10 xl:px-12 flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-0 mb-6 lg:mb-12">
          <div className="lg:w-1/2">
            <div className="mb-5">
              <h2 className="mb-3 text-2xl lg:text-[40px] lg:leading-12 font-bold">
                <p>Get Started With</p>
                <p className="text-[#fa6385]">Monkey Box Today!</p>
              </h2>
              <img src="images/heading-underline-red.svg" alt="" />
            </div>
            <p className="#6e6e6e text-sm lg:text-base mb-10 lg:mb-20">
              Ordering your favorite meals has never been simpler. Download our
              user-friendly app, available on both iOS and Android, or visit our
              website to explore our extensive menu. With just a few clicks, you
              can have your meals delivered straight to your kids school.
            </p>
            <div className="mt-8 flex items-start justify-start gap-5">
              <a href={playStoreLink}>
                <img
                  className="w-[167.04px] h-[48px] object-cover cursor-pointer"
                  src="images/googlePlay.png"
                  alt=""
                />
              </a>
              <a href={appStoreLink}>
                <img
                  className="w-[167.04px] h-[48px] object-cover cursor-pointer"
                  src="images/PlayStoreMB.png"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="lg:w-1/3 flex items-center justify-end gap-4 mt-6 lg:mt-6">
            {/* <img className='w-28 lg:w-40' src="images/app-section-splash-screen.png" alt="" />
              <img className='w-28 lg:w-40' src="images/app-section-home-screen.png" alt="" />
              <img className='w-28 lg:w-40' src="images/app-section-onboarding-screen.png" alt="" /> */}
            <img className="object-cover" src="images/screen.png" alt="" />
          </div>
        </div>
      </div>

      <div
        className="w-full relative bg-white py-16 lg:py-24"
        id="getInTouchSection"
      >
        <img
          className="absolute top-0 right-0 z-10 w-28 lg:w-40"
          src="images/getin-touch-section-corner.svg"
          alt=""
        />
        <div className="container flex items-center mx-auto px-5 lg:px-24 gap-8">
          <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-10 lg:gap-10 bg-[#f8f8f8] py-4 lg:py-10 px-5 lg:px-12 rounded-2xl">
            <div>
              <div className="mb-8 lg:mb-10">
                <div className="flex flex-col items-start mb-3 lg:mb-6">
                  <h1 className="mb-3 text-2xl lg:text-[40px] font-semibold">
                    Get In Touch
                  </h1>
                  <img
                    className="w-60 lg:w-auto"
                    src="images/heading-underline.svg"
                    alt=""
                  />
                </div>
                <p className="text-[#6e6e6e] text-sm lg:text-base font-medium">
                  Wants to collaborate
                </p>
              </div>
              <div className="flex gap-3 mb-5 lg:mb-10">
                <div className="w-8 h-8 lg:w-12 lg:h-12  flex items-center justify-center bg-[#f3faf7]">
                  <img
                    className="w-4 lg:w-6"
                    src="images/email-icon.svg"
                    alt=""
                  />
                </div>
                <div>
                  <p className="text-base lg:text-lg font-semibold mb-1">
                    Email
                  </p>
                  <div className="flex flex-col lg:flex-row gap-2 text-sm lg:text-sm">
                    <a
                      className="text-[#029769] lg:border-r-2 border-black pr-5"
                      href="mailto:info@monkeybox.in"
                    >
                      info@monkeybox.in
                    </a>
                    <span className="lg:hidden bg-black w-24 h-[1px]"></span>
                    <a
                      className="text-[#029769] lg:pl-5"
                      href="mailto:info@monkeybox.in"
                    >
                      support@monkeybox.in
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="w-8 h-8 lg:w-12 lg:h-12 flex items-center justify-center bg-[#f3faf7]">
                  <img
                    className="w-4 lg:w-6"
                    src="images/call-icon.svg"
                    alt=""
                  />
                </div>
                <div>
                  <p className="text-base lg:text-lg font-semibold mb-1">
                    Phone no.
                  </p>
                  <a
                    className=" text-sm lg:text-sm text-[#029769]"
                    href="tel:+919513370337"
                  >
                    +919513370337
                  </a>
                </div>
              </div>
            </div>
            <form
              onChange={contactUsFormChanges}
              onSubmit={handleSubmit(contactUsRequest)}
              className="getin-touch-form lg:w-1/2"
            >
              <div className="mb-4 lg:mb-8">
                <div className="border-b-2 border-[#9e9e9e] mb-1">
                  <input
                    className="appearance-none bg-transparent border-none w-full text-sm lg:text-sm font-semibold text-[#6e6e6e] mr-3 py-1 px-2 leading-tight focus:outline-none focus:ring-0 !bg-[#f8f8f8] "
                    placeholder="Name"
                    type="text"
                    {...register("name")}
                  />
                </div>
                {errors.name && (
                  <p className="text-sm text-red-500">{errors.name?.message}</p>
                )}
              </div>
              <div className="grid grid-cols-2 items-start gap-8 mb-4 lg:mb-8">
                <div className="mb-1">
                  <div className="border-b-2 border-[#9e9e9e] mb-1">
                    <input
                      className="appearance-none bg-transparent border-none w-full  text-sm lg:text-sm font-semibold text-[#6e6e6e] mr-3 py-1 px-2 leading-tight focus:outline-none focus:ring-0 !bg-[#f8f8f8] "
                      placeholder="Phone no"
                      type="tel"
                      max={10}
                      {...register("mobile", { required: true })}
                    />
                  </div>
                  {errors.mobile && (
                    <p className="text-sm text-red-500">
                      {errors.mobile?.message}
                    </p>
                  )}
                </div>
                <div className="mb-1">
                  <div className="border-b-2 border-[#9e9e9e] mb-1">
                    <input
                      className="appearance-none bg-transparent border-none w-full  text-sm lg:text-sm font-semibold text-[#6e6e6e] mr-3 py-1 px-2 leading-tight focus:outline-none focus:ring-0 !bg-[#f8f8f8] "
                      placeholder="Email"
                      type="text"
                      {...register("email", { required: true })}
                    />
                  </div>
                  {errors.email && (
                    <p className="text-sm text-red-500">
                      {errors.email?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="border-b-2 border-[#9e9e9e] mb-1">
                  <input
                    className="appearance-none bg-transparent border-none w-full  text-sm lg:text-sm font-semibold text-[#6e6e6e] mr-3 py-1 px-2 leading-tight focus:outline-none focus:ring-0 !bg-[#f8f8f8] "
                    placeholder="City"
                    type="text"
                    {...register("city", { required: true })}
                  />
                </div>
                {errors.city && (
                  <p className="text-sm text-red-500">{errors.city?.message}</p>
                )}
              </div>
              <div className="mb-4 mt-[30px]">
                <div className="border-b-2 border-[#9e9e9e] mb-1">
                  <input
                    className="appearance-none bg-transparent border-none w-full  text-sm lg:text-sm font-semibold text-[#6e6e6e] mr-3 py-1 px-2 leading-tight focus:outline-none focus:ring-0 !bg-[#f8f8f8] "
                    placeholder="Message"
                    type="text"
                    {...register("message", { required: true })}
                  />
                </div>
                {errors.message && (
                  <p className="text-sm text-red-500">
                    {errors.message?.message}
                  </p>
                )}
              </div>
              {isGetinTouchError ? (
                <p className="text-red-500 text-sm">{constactUsMessage}</p>
              ) : (
                <p className="text-green-600 text-sm">{constactUsMessage}</p>
              )}
              <div className="flex justify-end mt-[30px]">
                <button
                  type="submit"
                  className="ml-auto w-[100px] lg:w-[133px] h-[38px] lg:h-[60px]  drop-shadow-md p-[2px] bg-[#1C808A]  rounded-full shadow-loginButtonShadow hover:bg-black ease-linear duration-500"
                >
                  <div className="w-full flex items-center justify-center whitespace-nowrap  text-white text-sm lg:text-base font-medium h-full  hover:bg-black ease-linear duration-500 bg-[#029769] border-l-[2px] border-r-[2px] border-t-[2px] border-white rounded-full">
                    Submit
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="w-full relative bg-[#ebe0ff] py-8">
        <img
          className="absolute top-0 left-0 z-10 w-28 lg:w-60"
          src="images/register-now-section-corner.svg"
          alt=""
        />
        <div className="container mx-auto px-5 lg:px-12 gap-8">
          <div className="flex flex-col lg:flex-row lg:items-center justify-center gap-8 lg:gap-16">
            <img
              className="h-[14rem] lg:h-[22rem]"
              src="images/registernow-section-image.png"
              alt=""
            />
            <div>
              <div className="mb-4">
                <div className="mb-3 text-2xl lg:text-[40px] lg:leading-12 font-bold">
                  <p>Register Now For A</p>
                  <p className="text-[#029769]">Seamless Experience!</p>
                </div>
                <img
                  className="w-72 lg:w-auto fill-current-[#029769]"
                  src="images/heading-underline-green.svg"
                  alt=""
                />
              </div>
              <p className="text-[#6e6e6e] text-sm lg:text-base mb-6 lg:mb-10">
                Ordering meals has never been more convenient
              </p>
              <Link href={appStoreLink}>
                <button
                  className="w-[100px] lg:w-[133px] h-[38px] lg:h-[60px] drop-shadow-md p-[2px] bg-[#1C808A]  rounded-full shadow-loginButtonShadow hover:bg-black ease-linear duration-500"
                >
                  <div className="w-full flex items-center justify-center whitespace-nowrap  text-white text-sm lg:text-base font-medium h-full hover:bg-black ease-linear duration-500 bg-[#029769] border-l-[2px] border-r-[2px] border-t-[2px] border-white rounded-full">
                    Register
                  </div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* footer section */}
      <div className="w-full pt-12 lg:pt-24 pb-10 lg:pb-16">
        <div className="container mx-auto px-5 lg:px-10 xl:px-12">
          <div className=" flex flex-col lg:flex-row lg:items-center justify-between gap-7 lg:gap-0">
            <div className="lg:w-2/3">
              <p className="text-sm lg:text-base mb-4">COLLABORATION</p>
              <p className="text-lg lg:text-4xl lg:leading-[45px] font-bold mb-3">
                Are you a school, day-care centre or any institute, or parents?
                - who wanna keep the hungry monkeys and good food
              </p>
              <img
                className="fill-current-[#029769]"
                src="images/heading-underline.svg"
                alt=""
              />
            </div>
            <button className="w-[130px] h-[40px] lg:w-[173px] lg:h-[60px]  drop-shadow-md p-[2px] bg-[#1C808A]  rounded-full shadow-loginButtonShadow hover:bg-black ease-linear duration-500">
              <div 
                onClick={() => scrollToDiv("getInTouchSection")}
                className="w-full flex items-center justify-center whitespace-nowrap  text-white text-sm lg:text-base font-medium h-full hover:bg-black ease-linear duration-500 bg-[#029769] border-l-[2px] border-r-[2px] border-t-[2px] border-white rounded-full">
                Contact Now
              </div>
            </button>
          </div>
          <div className="h-[2px] my-12 w-full bg-[#ddd]"></div>
          <div className="flex flex-col lg:flex-row justify-between gap-6 w-full">
            <div className="lg:w-1/4 mb-4 lg:mb-0">
              <div className="flex items-center justify-start gap-3 mb-6">
                <img
                  className="w-12"
                  src="images/MonkeyBox-logo-150x150.webp"
                  alt=""
                />
                <p className="text-xl font-semibold">Monkey Box</p>
              </div>
              <p className="text-[#8e8e8e] text-sm mb-6">
                Monkey Box No. 42/3/C2, Haralukunte village, Bommanahalli,
                B.B.M.P South, Karnataka-560068
              </p>
              <div className="flex gap-6">
                <Link href={'https://m.facebook.com/monkeyboxfoods/'}>
                  <img
                    src="images/facebook-icon.svg"
                    alt=""
                    className="hover:scale-110 ease-linear hover:duration-500 cursor-pointer"
                  />
                </Link>
                <Link href={'https://www.instagram.com/monkeyboxindia/?hl=en'}>
                  <img
                    src="images/instagram-icon.svg"
                    alt=""
                    className="hover:scale-110 ease-linear hover:duration-500 cursor-pointer"
                  />
                </Link>
                <img
                  src="images/twiter-icon.svg"
                  alt=""
                  className="hover:scale-110 ease-linear hover:duration-500 cursor-pointer"
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-start justify-start gap-6 lg:gap-12">
              <div>
                <p className="text-base font-semibold mb-4 ml-auto">Company</p>
                <ul>
                  <li onClick={() => scrollToDiv("plan")} className="text-[#8e8e8e] text-sm mb-3 hover:text-[#029769] hover:underline hover:ease-linear hover:duration-500 cursor-pointer">
                    Plan
                  </li>
                  <li className="text-[#8e8e8e] text-sm mb-3 hover:text-[#029769] hover:underline hover:ease-linear hover:duration-500 cursor-pointer">
                    Happy Client
                  </li>
                  <li onClick={() => scrollToDiv("getInTouchSection")} className="text-[#8e8e8e] text-sm mb-3 hover:text-[#029769] hover:underline hover:ease-linear hover:duration-500 cursor-pointer">
                    Get In Touch
                  </li>
                  <Link href={playStoreLink}>
                    <li className="text-[#8e8e8e] text-sm mb-3 hover:text-[#029769] hover:underline hover:ease-linear hover:duration-500 cursor-pointer">
                      Get App
                    </li>
                  </Link>
                </ul>
              </div>
              <div>
                <p className="text-base font-semibold mb-4">Help</p>
                <ul>
                  <li className="text-[#8e8e8e] text-sm mb-3 hover:text-[#029769] hover:underline hover:ease-linear hover:duration-500 cursor-pointer">
                    <Link href={'/terms-and-conditions'}>
                      Terms & Conditions
                    </Link>
                  </li>
                  <li className="text-[#8e8e8e] text-sm mb-3 hover:text-[#029769] hover:underline hover:ease-linear hover:duration-500 cursor-pointer">
                    Refund & Cancellation
                  </li>
                  <li onClick={() => scrollToDiv("getInTouchSection")} className="text-[#8e8e8e] text-sm mb-3 hover:text-[#029769] hover:underline hover:ease-linear hover:duration-500 cursor-pointer">
                    Get In Touch
                  </li>
                  <Link href={appStoreLink}>
                    <li className="text-[#8e8e8e] text-sm mb-3 hover:text-[#029769] hover:underline hover:ease-linear hover:duration-500 cursor-pointer">
                      Get App
                    </li>
                  </Link>
                </ul>
              </div>
              <div>
                <p className="text-base font-semibold mb-4">Get App</p>
                <div className="mt-6 flex flex-col items-start justify-start gap-5">
                  <Link href={playStoreLink}>
                    <img
                      className="w-[167.04px] h-[48px] object-cover cursor-pointer"
                      src="images/googlePlay.png"
                      alt=""
                    />
                  </Link>
                  <Link href={appStoreLink}>
                    <img
                      className="w-[167.04px] h-[48px] object-cover cursor-pointer"
                      src="images/PlayStoreMB.png"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="relative h-[2px] mt-10 w-full bg-[#ddd]">
            <div className="whitespace-nowrap absolute left-1/2 top-1/2 px-2 lg:px-12 py-1 bg-white -translate-x-1/2 -translate-y-1/2 text-[#9e9e9e] text-sm lg:text-base">
              © Copyright Monkey Box. 2024
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageV3;
